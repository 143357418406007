.under-construction-page {
  box-shadow: 0px 13px 33px rgb(0 31 36 / 14%);
  height: 100vh;
  background-color: #f3f9fb;
  .under-construction-nav {
    background-color: #001f24;
    padding: 12px 36px 12px 36px;
    max-height: 100px;
    height: 66px;
  }
  .mailWrapper a {
    color: #cddadd;
    font-family: "Open Sans", -apple-system, Helvetica, Arial, sans-serif !important;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 18px;
    float: right;
    text-align: right;
    padding: 12px 0;
    text-decoration: none;
  }
  .elementHeading {
    color: #001f24;
    font-size: 20px;
    padding-bottom: 30px;
    font-weight: 700;
  }
  .elementText {
    text-align: left;
    color: #495559;
    font-size: 16px;
    font-weight: 400;
  }
  .under-construction {
    margin-top: 40px;
    margin-bottom: 40px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 0px;
    position: absolute;
    font-family: "Open Sans", -apple-system, Helvetica, Arial, sans-serif !important;
    .element-wrapper {
      box-sizing: border-box;
      max-width: 360px;
      width: 360px;
      background-color: #ffffff;
      line-height: 1.5;
      font-family: "Roboto", sans-serif !important;
      word-wrap: break-word;
      padding: 30px 25px 25px 30px;
    }
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 576px) {
  .under-construction-page {
    .container,
    .container-sm {
      max-width: 540px;
    }
  }
}
@media (min-width: 768px) {
  .under-construction-page {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }
}
@media (min-width: 992px) {
  .under-construction-page {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }
}
@media (min-width: 1200px) {
  .under-construction-page {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px;
    }
  }
}
@media (min-width: 1400px) {
  .under-construction-page {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1320px;
    }
  }
}
