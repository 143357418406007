$tablet_view: 992/1200;
.slick-slide iframe.wistia_embed,
.NEWS_PARENT iframe.wistia_embed {
  padding-bottom: 20px;
}
[id="PROFILE"] iframe.wistia_embed {
  padding-bottom: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slick-slide iframe.wistia_embed,
  .NEWS_PARENT iframe.wistia_embed {
    padding-bottom: 20px * $tablet_view;
  }
  [id="PROFILE"] iframe.wistia_embed {
    padding-bottom: 35px * $tablet_view;
  }
}
@media only screen and (max-width: 991px) {
  .slick-slide iframe.wistia_embed,
  .NEWS_PARENT iframe.wistia_embed {
    padding-bottom: 20px;
  }
  [id="PROFILE"] iframe.wistia_embed {
    padding-bottom: 35px;
  }
}
