//Output related css lines
.boradcast-assets-only .container,
.broadcast_text_assets_wrapper .container,
.broadcast_text_wrapper .container {
  height: 100%;
  overflow-y: hidden;
}
// .broadCastTemplpatesWrapper .boradcast_header_main_wrapper {
//   height: 76px;
//   top: 0;
// }
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
}
.inline-textEditor:after {
  display: none;
}
.output-body-class {
  .socket {
    background: #ffffff;
    color: #000000;
  }
  .socket-hrader,
  .progress-bar {
    background-color: #0d6efd;
  }
  .socket-title,
  .mdi-window-minimize {
    color: #ffffff;
  }
  .socket-body {
    background: #ffffff !important;
  }
}
