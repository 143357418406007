.modal-header {
  .glyphicon-remove-sign,
  .fa-times-circle {
    display: none;
  }
}
.changeCoverPicture {
  display: none;
}
.mce-content-body {
  [data-mce-selected="inline-boundary"] {
    background-color: transparent !important;
  }
}
.mce-content-body .mce-item-anchor {
  background-color: transparent !important;
}
ol,
ul {
  li {
    strong {
      font-weight: 700;
    }
  }
}

//To disable forgot password button in review URL
.disabled {
  pointer-events: none;
  color: #aaa !important;
  // font-style: italic;
}

// REPORT UPDATE TAB CSS
// Report update tab hide on single tab & update off
.report-updates-tab.reportUpdateOff {
  display: none;
}
// vertical center alignment
.report-updates-tab .tab-wrapper li .report-update-name {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: relative;
  &.ellipses {
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    hyphens: auto;
    display: block;
  }
}
.fund_list {
  cursor: pointer;
}
.panelDiv,
.newFooterLayout,
.addCarouselPanelOuter,
.slideDiv,
.notificationDiv {
  display: none !important;
}
.carousel-control {
  display: inherit !important;
}

/**
 ARROW ISSUE FIX ---> Gallery panel arrow UI disportion fix
*/
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-top: -10px\9;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
#PHOTO_GALLERY .carousel-indicators {
  display: none !important;
}

//Tenant specific css
footer {
  .container-fluid {
    height: auto;
  }
}
.report-fund-list {
  // display: none !important;
}
//Maximum content panel added css
.disabled.maximum-disabled {
  pointer-events: auto;
  cursor: auto;
}

// Fixed editor top bar
#rootComponent {
  .header-action {
    position: fixed;
    z-index: 18;
    height: 70px;
    .h-inherit {
      height: auto;
    }
  }
}
#rootHtml {
  padding-top: 70px;
}

//asset editor
.replace-buttonDiv {
  position: absolute !important;
  z-index: 25;
  height: 50px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none !important;
}
//Hide lock icon for broadcast editor
.broadCastTemplpatesWrapper {
  .inline-textEditor:after {
    display: none !important;
  }
}
#greetingsPanel {
  .inline-textEditor:after {
    display: none;
  }
}

.navbar-fixed-bottom,
.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
}

/******* COMMON ELEMENTS CSS *********/

div[tabindex="0"]:not([type="button"]) {
  outline: none;
}
.web-tab-only {
  display: block;
}
.mob-only {
  display: none;
}
/**************************************************
* REPORT UPDATE GENERIC CSS START
**************************************************/
//variable declaration
$tablet_view: 992/1200;
$white-color: #ffffff;
@mixin textStyling() {
  color: #4a4a4a;
  font-family: "Source Sans Pro";
  font-size: 17px;
  font-weight: 700;
  line-height: 19px;
  text-decoration: none;
  display: inline-block;
  min-width: 30px;
}
.report-updates-tab {
  padding: 0 75px;
  .tab-wrapper {
    list-style: none;
    margin: 0;
    display: inline-flex;
    display: -webkit-inline-flex;
    li {
      float: left;
      text-align: center;
      margin-left: 5px;
      padding: 27px 0px 22px;
      min-width: 79px;
      width: inherit;
      cursor: pointer;
      height: 73px;
      &:first-child {
        margin-left: 0;
      }
      .report-update-name {
        @include textStyling();
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        position: relative;
      }
    }
  }
}
.contact-page-wrapper [sectionid="contact3"],
#greetingsPanel [sectionid="contact3"] {
  p {
    margin: 0;
  }
}
.output-only a[onclickprivate]:hover {
  cursor: pointer !important;
}
/************** mediaquery  tablet View code start********************/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  @mixin textStyling() {
    font-size: 17px * $tablet_view;
    line-height: 19px * $tablet_view;
  }
  .report-updates-tab {
    padding: 0 75px * $tablet_view;
    .tab-wrapper {
      li {
        margin-left: 5px * $tablet_view;
        padding: 27px * $tablet_view 0px 22px * $tablet_view;
        min-width: 79px * $tablet_view;
        height: 73px * $tablet_view;
        .report-update-name {
          @include textStyling();
        }
      }
    }
  }
}

/************** mediaquery Mobile  View code start********************/
@media only screen and (max-width: 991px) {
  .web-tab-only {
    display: none;
  }

  .mob-only {
    display: block;
  }
  .report-updates-tab {
    width: 100%;
    padding: 0;
    .tab-wrapper {
      display: block;
      max-height: 152px;
      position: absolute;
      top: 44px;
      z-index: 999;
      li {
        height: auto;
        float: none;
        height: 42px;
        margin-left: 0;
        padding: 11px 40px 11px 11px;
        min-width: 100%;
        text-decoration: none;
        box-shadow: none;
        .report-update-name {
          font-size: 15px;
          line-height: 17px;
          display: inline-flex;
          display: -webkit-inline-flex;
          justify-content: center;
          align-items: center;
          -webkit-align-items: center;
          text-align: center;
          text-decoration: none;
          top: inherit;
          transform: inherit;
          -webkit-transform: inherit;
          position: inherit;
        }
      }
      &.collapsable {
        overflow: auto;
      }
    }
  }
}
/**************************************************
* REPORT UPDATE GENERIC CSS END
**************************************************/

/* Only for the Output part to make elipsses for 40 max character count
* It's handling only the output part css and mobile view
*/

.report-updates-tab .report-update-data .ellipses,
.report-updates-tab .report-update-data .ellipses p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report-updates-tab .tab-wrapper .report-update-data {
  .report-update-name.ellipses {
    display: block;
  }
}
// Mobile view css report update max 40 char
@media only screen and (max-width: 991px) {
  .report-updates-tab .tab-wrapper .report-update-data {
    .report-update-name.ellipses {
      width: inherit;
    }
  }
  .print-report-modal {
    .select-fund-drop-down {
      margin-bottom: 15px;
    }
  }
}

.passcode-inputs {
  .row {
    margin: auto; /* center horizontally */

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
  }

  input {
    margin: 0.5rem 0.5rem;
    padding: 0.6rem;
    max-width: 42px;
    text-align: center;
    color: #565a5e;
    border-radius: 4px 4px 0 0;
    background-color: #e8e8e8;
    border-bottom: 2px solid #727272;
    &.errorBgColor {
      color: red;
      border-color: red;
    }
    &:hover,
    :focus {
      border-bottom: 2px solid #005cd5;
      background-color: #d5d5d5;
    }
  }

  /* remove the up/down spinner in number input
     source - https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
  */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.cookie-modal,
#newPasswordMagicLink,
#newPassword,
#expiryMagicLink,
#setupPasscode,
#setupPasscodeOtp {
  @media (min-width: 576px) {
    &.modal-dialog {
      max-width: 450px;
    }
  }
  .modal-content {
    border-radius: 1px;
    .modal-header {
      border-radius: 1px;
      background-color: transparent !important;
      color: #212529 !important;
      border-bottom: 0;
      h4 {
        font-size: 20px;
      }
    }
    .modal-body {
      p {
        color: #565a5e;
        margin: 0;
      }
    }
    .modal-footer {
      border-top: 0;
      justify-content: right !important;
      button {
        border-radius: 1px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        &.btn-light {
          background-color: transparent !important;
          border: none;
        }
      }
    }
  }
}

#expiryMagicLink .modal-content .modal-header {
  background-color: #e7716a !important;
  color: #ffffff !important;
}

#setupPasscodeOtp {
  @media (min-width: 576px) {
    &.modal-dialog {
      max-width: 308px;
    }
  }
}

// print report modal in safari browser fix
.print-report-modal {
  .row:before,
  .row:after {
    width: 0 !important;
  }
  .select-fund-drop-down {
    margin-left: 25px;
    .custom-select.ms-3 {
      margin: 0 !important;
    }
  }
}

.menu-item-logout {
  cursor: pointer;
}
